<template>
  <main role="main" class="main">
    <div class="mobile-vh min-h-100 py-2 px-4 mobi-rounded-top">
      <b-row class="custom-height">
        <b-col lg="10" offset-lg="1">
          <div class="h-md-100">
            <b-form-group class="text-center v-center">
              <span class="indicator bg-success">
                <div class="d-table h-100 w-100">
                  <div class="d-table-cell align-middle">
                    <feather type="check"></feather>
                  </div>
                </div>
              </span>
              <h2 class="color-navy-blue">Payment succeeded!</h2>
              <p>Thank you for choosing Truzo.<br />Please, tell us about your experience during this process.</p>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('auth', ['logout']),
    logMeOff: function () {
      this.logout()
      this.$router.push('/')
    }
  }
}
</script>